import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { animated, useSpring } from 'react-spring'
import Brand from '../components/Brand'
import Breadcrumbs from '../components/Breadcrumbs'
import Layout from '../components/Layout'
import Privacy from '../components/Privacy'
import SEO from '../components/Seo'
import text from '../components/Text/text'

const breadcrumbs = [
    {
        link: '/',
        title: 'Home',
    },
    {
        link: null,
        title: 'Privacy Policy',
    },
]

const PrivacyPage = (): ReactElement => {
    const animation = useSpring({
        delay: 700,
        opacity: 1,
        transform: 'translate3d(0,0px,0)',
        from: { opacity: 0, transform: 'translate3d(0,30px,0)' },
    })
    return (
        <>
            <SEO title="Privacy Policy" />
            <Layout>
                <animated.div
                    style={animation}
                    className="w-full max-w-3xl mx-auto text-white"
                >
                    <div className="flex px-4 py-4 md:py-8 text-white">
                        <Link to="/">
                            <Brand />
                        </Link>
                    </div>
                    <div className="p-4 overflow-hidden">
                        <Breadcrumbs steps={breadcrumbs} />
                        <h1 className={text.H1}>Privacy Policy</h1>
                        <p className={`${text.P_2} opacity-50`}>
                            This policy was updated on March 12, 2019
                        </p>
                        <Privacy />
                    </div>
                </animated.div>
            </Layout>
        </>
    )
}

export default PrivacyPage
