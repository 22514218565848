import { Link } from 'gatsby'
import React from 'react'

interface Steps {
    link: string | null
    title: string
}

interface Props {
    steps: Array<Steps>
    classNames?: string | null
}

const Breadcrumbs = ({ steps, classNames }: Props) => {
    console.log(steps)

    return (
        <div className={`${classNames} flex mb-8 text-lg`}>
            {steps.map((item, index) => (
                <React.Fragment key={index}>
                    {!!item.link ? (
                        <Link to={item.link}>
                            <div className="leading-loose">{item.title}</div>
                        </Link>
                    ) : (
                        <div className="leading-loose opacity-50">
                            {item.title}
                        </div>
                    )}

                    {index + 1 !== steps.length && (
                        <div className="leading-loose mx-2">/</div>
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}

export default Breadcrumbs
